import { graphql } from 'gatsby';
import { Helmet, Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { NarrowCenteredContainer } from '../components';
import { bgBannerLarge, error404Img } from '../images';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('404.page-title')}</title>
      </Helmet>
      <section className="text-white bg-cover relative" id="banner">
        <div
          className="h-[calc(100%+var(--full-menu-height))] w-full absolute -z-50 top-[calc(-1*var(--full-menu-height))]"
          style={{ backgroundImage: `url("${bgBannerLarge}")` }}
        />
      </section>
      <section className="py-20 md:pt-36 md:pb-32">
        <NarrowCenteredContainer>
          <div className="max-w-4xl mx-auto text-center">
            <div className="max-w-md mx-auto">
              <img src={error404Img} alt="404 image" />
            </div>
            <h2 className="font-secondary font-semibold text-header text-2xl md:text-5xl">
              {t('404.header')}
            </h2>
            <p className="md:text-2xl mt-6">{t('404.text')}</p>
            <Link className="button-primary-inverted inline-block mt-6" to="/">
              {t('404.button')}
            </Link>
          </div>
        </NarrowCenteredContainer>
      </section>
    </>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
